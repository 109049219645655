angular.module('app').filter('outcome', function () {
    return function (outcome) {
        var text;

        if (_.isUndefined(outcome)) return text;

        switch (outcome) {

            case "AWAITING_ALLOCATION":
                text = "Awaiting allocation";
                break;
            case "ABANDONED":
                text = "Abandoned";
                break;
            case "ALLOCATED":
                text = "Allocated to driver";
                break;
            case "EN_ROUTE_TO_PICKUP":
                text = "En-route to pickup";
                break;
            case "APPROACHING_PICKUP":
                text = "Approaching pickup";
                break;
            case "ARRIVED_AT_PICKUP":
                text = "Arrived at pickup";
                break;
            case "CANCELLED":
                text = "Cancelled";
                break;
            case "EN_ROUTE_TO_DESTINATION":
                text = "En-route to destination";
                break;
            case "APPROACHING_DESTINATION":
                text = "En-route to destination";
                break;
            case "EXPIRED":
                text = "Expired";
                break;
            case "COMPLETED":
                text = "Completed";
                break;
            default:
                text = "n/a";
        }

        return text;

    };
});